import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Ad, AreaOfInterest } from "../../client/api";
import { adPositionItems } from "../../config/constants";
import { AgChartOptions } from "ag-charts-community";
import { showTranslated, translate } from "../../config/translator";
import { ClavaContext } from "../../config/contexts";
import useBoolState from "../../hooks/useBoolState";
import Breadcrumps from "../../components/Layout/Breadcrumps";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import ItemBlockHeader from "../../components/Layout/ItemBlockHeader";
import { faLineChart } from "@fortawesome/pro-solid-svg-icons";
import ClavaSwitch from "../../components/Atoms/ClavaSwitch";
import { AgCharts } from "ag-charts-react";
import { isAdActive, makeAoiItems } from "../../config/utils";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import ClavaSelect from "../../components/Atoms/ClavaSelect";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { useNavigate } from "react-router";

const AdvertiserStatistics = () => {
  const { l, aois, regions, filterAoi } = useContext(ClavaContext);
  const [items, setItems] = useState<Ad[]>();
  const [showActiveOnly, toggleShowActiveOnly] = useBoolState(true);
  const [selectedAoi, setSelectedAoi] = useState<string>(filterAoi);
  const { call, loading } = useServer(true);
  useEffect(() => {
    call("searchAds", [" ", 0, 100000]).then(setItems);
  }, [call]);
  const positionItems = useMemo(() => {
    return adPositionItems(l);
  }, [l]);
  const activeAds = useMemo(() => {
    if (!items)
      return [];
    if (showActiveOnly)
      return items.filter(ad => isAdActive(ad));
    return items;
  }, [items, showActiveOnly]);
  const adsPerAoi = useMemo(() => {
    return aois.map<{ aoi: AreaOfInterest, ads: Ad[] }>(area => {
      return {
        aoi: area,
        ads: activeAds.filter(ad => !!ad.areasOfInterest.find(aoi2 => aoi2.id === area.id))
      };
    });
  }, [aois, activeAds]);
  const regionChartOptions = useMemo<AgChartOptions>(() => {
    return {
      title: {
        text: translate("byArea", l),
        fontFamily: "Gilroy",
        fontWeight: "bold",
        fontSize: 18
      },
      data: adsPerAoi.map(area => ({
        label: showTranslated(area.aoi.name, l),
        amount: area.ads.length
      })),
      series: [{
        type: "pie",
        legendItemKey: "label",
        angleKey: "amount"
      }]
    };
  }, [adsPerAoi, l]);
  const positionChartOptions = useMemo(() => {
    const filteredAdsPerAois = adsPerAoi.filter(ao => {
      if (selectedAoi.startsWith("aoi_")) {
        const id = parseInt(selectedAoi.replace("aoi_", ""), 10);
        return ao.aoi.id === id;
      } else if (selectedAoi.startsWith("region_")) {
        const id = parseInt(selectedAoi.replace("region_", ""), 10);
        return ao.aoi.regionId === id;
      }
      return true;
    });
    return filteredAdsPerAois.map<{
      aoi: AreaOfInterest,
      options: AgChartOptions | undefined
    }>(area => {

      if (area.ads.length === 0) {
        return {
          aoi: area.aoi,
          options: undefined
        };
      }
      const data = positionItems.map((item) => {
        return {
          ...item,
          amount: area.ads.filter(a => a.position === item.key).length
        };
      });
      return {
        aoi: area.aoi,
        options: {
          title: {
            text: showTranslated(area.aoi.name, l)
          },
          data,
          series: [{
            type: "pie",
            legendItemKey: "label",
            angleKey: "amount"
          }]
        }
      };
    });
  }, [positionItems, adsPerAoi, l, selectedAoi]);
  const prioChartOptions = useMemo(() => {
    const filteredAdsPerAois = adsPerAoi.filter(ao => {
      if (selectedAoi.startsWith("aoi_")) {
        const id = parseInt(selectedAoi.replace("aoi_", ""), 10);
        return ao.aoi.id === id;
      } else if (selectedAoi.startsWith("region_")) {
        const id = parseInt(selectedAoi.replace("region_", ""), 10);
        return ao.aoi.regionId === id;
      }
      return true;
    });
    return filteredAdsPerAois.map<{
      aoi: AreaOfInterest,
      options: AgChartOptions | undefined
    }>(area => {
      if (area.ads.length === 0) {
        return {
          aoi: area.aoi,
          options: undefined
        };
      }
      const data = positionItems.map((pos) => {
        let sum = 0;
        const cur = area.ads.reduce((prev, ad) => {
          let prio = ad.priority;
          if (ad.position !== pos.key) {
            prio = 0;
          }
          if (sum >= 100) {
            prio = 0;
          }
          if (sum + prio > 100) {
            prio = 100 - sum;
          }
          sum += prio;
          return {
            ...prev,
            [ad.name]: prio
          };
        }, {
          label: pos.label
        });
        return {
          ...cur,
          AdMob: 100 - sum
        };
      });
      return {
        aoi: area.aoi,
        options: {
          title: {
            text: showTranslated(area.aoi.name, l)
          },
          data,
          series: [{
            type: "bar",
            xKey: "label",
            yKey: "AdMob",
            stacked: true,
            yName: "AdMob"
          }].concat(area.ads.map(ad => ({
            type: "bar",
            xKey: "label",
            yKey: ad.name,
            stacked: true,
            yName: ad.name
          })))
        } as AgChartOptions
      };
    });
  }, [positionItems, adsPerAoi, l, selectedAoi]);
  const aoiItems = useMemo(() => {
    return makeAoiItems(regions, aois, l);
  }, [l, regions, aois]);
  const onChangeAoi = useCallback((val: string | undefined) => {
    if (val)
      setSelectedAoi(val);
  }, []);
  const navigator = useNavigate();
  const onNavBack = useCallback(() => {
    navigator("/advertising");
  }, [navigator]);
  return (
    <>
      <Breadcrumps>
        <ClavaButton onClick={onNavBack} lightSecondary
                     className="mr-2">{translate("back", l)}</ClavaButton>

      </Breadcrumps>
      <RoundedBlock>

        <ItemBlockHeader name={translate("adStatistics", l)} image={faLineChart}>
          <div className="flex flex-row items-center">
            <ClavaSelect uniqueId={"aoi-filter"} items={aoiItems} onChange={onChangeAoi} withNone
                         value={selectedAoi} label={translate("province", l)}
                         className="mx-2 min-w-fit" />

            <ClavaSwitch onChange={toggleShowActiveOnly} label={translate("activeOnly", l)}
                         value={showActiveOnly} />
          </div>
        </ItemBlockHeader>
        <LoadingBlock isLoading={loading} className="p-2">
          <h2
            className="font-bold text-lg pt-2 border-b border-b-primary-50 border-t-2 border-t-primary">{translate("byPosition", l)}</h2>
          <span
            className="text-muted dark:text-muted-dark pt-2 border-b border-b-primary-50">{translate("byAreaCont", l)}</span>
          <AgCharts options={regionChartOptions} />

          <h2
            className="font-bold text-lg pt-2 border-b border-b-primary-50 border-t-2 border-t-primary">{translate("byPosition", l)}</h2>
          <span
            className="text-muted dark:text-muted-dark pt-2 border-b border-b-primary-50 w-full">{translate("byPositionCont", l)}</span>
          <div className="flex flex-row flex-wrap items-start justify-start">
            {positionChartOptions.map(item =>
              !!item.options ?
                (<AgCharts options={item.options} key={"pos-chart-" + item.aoi.id}
                           className={"flex-1 max-w-2xl"} />)
                : null)}
          </div>
          <h2
            className="font-bold text-lg pt-2 border-b border-b-primary-50 border-t-2 border-t-primary">{translate("byPrio", l)}</h2>
          <span
            className="text-muted dark:text-muted-dark pt-2 border-b border-b-primary-50">{translate("byPrioCont", l)}</span>
          {prioChartOptions.map((item) => !!item.options ? (
            <AgCharts options={item.options} key={"prio-chart-" + item.aoi.id}
                      className={"flex-1 max-w-2xl"} />
          ) : null)
          }
        </LoadingBlock>
      </RoundedBlock>
    </>
  );
};

export default AdvertiserStatistics;
